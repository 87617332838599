"use client";

import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import http from "@/lib/http";
import { useToast } from "@chakra-ui/react";
// import classNames from 'classnames';
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";
import { ICreateSavings } from "@/types/global.types";
import { removeUnderscore, upperCaseFirst } from "@/utils/helpers";
import { useNavigate } from "react-router-dom";
import { Form, Tooltip } from "antd";
import { HiInformationCircle } from "react-icons/hi";

function BusinessServicesSavingsCreateClient() {
  // const { user } = useApp();
  const navigate = useNavigate();
  const toast = useToast();
  const [form] = Form.useForm();

  const [isSending, setIsSending] = useState(false);

  const [createSavingsInput, setCreateSavingsInput] = useState<ICreateSavings>({
    currency: "",
    type: "",
    quantity: "",
    name: "",
    collection: "",
    rate: "",
    rate_amount_plus: "",
    rate_amount_plus_limit: "",
    // penalty: "",
    // penalty_amount_plus: "",
    // penalty_amount_plus_limit: "",
    min_amount: "",
    max_amount: "",
    min_duration: "",
    max_duration: "",
    active: "",
    penalty_on: "",
    auto_approve: "",
    auto_decline: "",
    send_request_status_sms: "",
    send_request_status_email: "",
    // interest_settlement: "Upfront",
    interest_settlement: "",
    allow_break: "",
    description: "",
    penalty: "",
    penalty_type: "",
    penalty_capped: "",
    penalty_amount_plus: "",
    penalty_amount_plus_limit: "",
    request_info: [],
  });

  const infos = [
    {
      key: "currency",
      value: "The currency for conducting savings activities",
    },
    {
      key: "select",
      value: "Options to choose from for savings currency types",
    },
    {
      key: "type",
      value: "The specific kind of savings account or instrument",
    },
    {
      key: "quantity",
      value: "The total number or value of savings put aside",
    },
    {
      key: "name",
      value: "The designation or title of the savings account",
    },
    {
      key: "collection",
      value:
        "The method or frequency of depositing funds into savings (e.g., monthly, annually)",
    },
    {
      key: "rate",
      value: "The interest rate applicable for the savings",
    },
    {
      key: "rate_amount_plus",
      value: "Additional earnings over the standard rate",
    },
    {
      key: "rate_amount_plus_limit",
      value: "The maximum threshold for additional earnings",
    },
    {
      key: "penalty",
      value:
        "Conditions under which penalties may apply to the savings account",
    },
    {
      key: "penalty_amount_plus",
      value: "Additional penalty fees that may be charged",
    },
    {
      key: "penalty_amount_plus_limit",
      value: "The upper limit on penalty fees",
    },
    {
      key: "min_amount",
      value: "Minimum savings amount required to open or maintain the account",
    },
    {
      key: "max_amount",
      value: "Maximum savings amount allowed in the account",
    },
    {
      key: "min_duration",
      value: "Shortest time required for maintaining savings",
    },
    {
      key: "max_duration",
      value: "Longest allowable period for the savings account term",
    },
    {
      key: "active",
      value:
        "Status showing whether the savings option is currently active or conditional",
    },
    {
      key: "penalty_on",
      value: "Circumstances triggering penalties on savings",
    },
    {
      key: "auto_approve",
      value: "Automatic approval settings for new savings deposits or accounts",
    },
    {
      key: "auto_decline",
      value: "Automatic rejection conditions for requests or transactions",
    },
    {
      key: "send_request_status_sms",
      value: "Settings for sending status updates via SMS for savings requests",
    },
    {
      key: "send_request_status_email",
      value:
        "Settings for sending status updates via email for savings requests",
    },
    {
      key: "interest_settlement",
      value: "The method or frequency of distributing the interest earned",
    },
    {
      key: "allow_break",
      value:
        "Permissions for interrupting the savings plan under specific conditions",
    },
    {
      key: "description",
      value:
        "A summary detailing objectives, benefits, and potential considerations for the savings account",
    },
  ];

  const checkForInfo = (key: string) => {
    let checking = infos.filter((item) => item.key === key);
    if (checking.length > 0) {
      return (
        <div>
          <Tooltip title={checking[0].value}>
            <HiInformationCircle className="w-5 h-5 pl-1 !text-yellow-500 cursor-pointer" />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const isSelectTag = {
    currency: ["NGN", "USD"],
    type: ["self_onetime", "self_recurring"],
    // "type": ["DIRECT", "ALTERNATIVE"],
    active: ["Yes", "No"],
    rate_type: ["flat", "percentage"],
    penalty_type: ["flat", "percentage"],
    // "rate_counter": ["Day", "Week", "Month", "Year"],
    penalty_counter: ["Day", "Week", "Month", "Year"],
    penalty_on: ["recollection_amount", "initial_amount", "interest_amount"],
    auto_approve: ["Yes", "No"],
    auto_decline: ["Yes", "No"],
    send_late_repayment_sms: ["Yes", "No"],
    send_late_repayment_email: ["Yes", "No"],
    auto_debit_card_on_repayment_date: ["Yes", "No"],
    auto_debit_bank_account_on_repayment_date: ["Yes", "No"],
    allow_new_request_on_current_savings: ["Yes", "No"],
    send_request_status_sms: ["Yes", "No"],
    send_request_status_email: ["Yes", "No"],
    send_relation_overdue_repayment_sms: ["Yes", "No"],
    send_relation_overdue_repayment_email: ["Yes", "No"],
    // "repayment_extension_allowed": ["Yes", "No"],
    allowed: ["Yes", "No"],
    required: ["Yes", "No"],
    interest_settlement: ["Upfront", "Maturity"],
    allow_break: ["Yes", "No"],
  };

  const isSelectTagRequireInfo = {
    required: ["Yes", "No"],
    type: [
      "text",
      "alphanumeric",
      "number",
      "video",
      "image",
      "audio",
      "select",
    ],
  };

  const IsTextArea = ["description"];

  async function createSavings() {
    setIsSending(true);
    console.log(createSavingsInput);
    try {
      const resp = await http("services/addon/savings/create", {
        ...createSavingsInput,
        currency: createSavingsInput?.currency?.toLowerCase(),
      });
      console.log("create resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/d/services/savings");
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    fieldName: string
  ) => {
    setCreateSavingsInput((prevInput) => ({
      ...prevInput,
      [fieldName]: event.target.value,
    }));
  };

  const handleInputChange_require_info = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string,
    index: number
  ) => {
    if (fieldName !== "options") {
      setCreateSavingsInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index ? { ...info, [fieldName]: event.target.value } : info
        ),
      }));
    } else {
      setCreateSavingsInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index
            ? {
                ...info,
                [fieldName as any]: formatRequiredInfoOptionsStore(
                  event.target.value
                ),
              }
            : info
        ),
      }));
    }
  };

  function deleteRequestInfo(index: number) {
    setCreateSavingsInput((prevInput) => ({
      ...prevInput,
      request_info: prevInput.request_info.filter((_, i) => i !== index),
    }));
  }

  function addMoreRequestInfo() {
    setCreateSavingsInput((prevInput) => ({
      ...prevInput,
      request_info: [
        ...prevInput.request_info,
        {
          name: "",
          required: "",
          options: [],
          value: "",
          type: "",
        },
      ],
    }));
  }

  function formatRequiredInfoOptionsView(data: string[]): string {
    if (data.length > 0) {
      let r = "";
      data.forEach((e, i) => {
        if (i !== data.length - 1) {
          r += e + ",";
        } else {
          r += e;
        }
      });
      return r;
    }
    return "";
  }

  function formatRequiredInfoOptionsStore(data: string): string[] {
    const arr = data.split(",");
    let s: string[] = [];
    if (arr.length > 0) {
      arr.forEach((e) => {
        s.push(e);
      });
      return s;
    }
    return [];
  }

  const getActualSelectValue = (value: string) => {
    switch (value) {
      case "self_onetime":
        return "Individual One time";

      case "self_recurring":
        return "Individual Recurring";

      case "recollection_amount":
        return "Recollection Amount";

      case "initial_amount":
        return "Initial Amount";

      case "interest_amount":
        return "Interest Amount";

      default:
        return value;
    }
  };

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/details");
            }}
          >
            Services
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/savings");
            }}
          >
            Savings
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/savings/create");
            }}
          >
            Create
          </div>
        </div>
      </div>

      <div className="mb-8 text-xl">Create Savings</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      <div className="w-full mb-10">
        {/* <div className='flex items-center justify-between mb-10'>
                    <div className='flex items-center gap-3'>
                        <div className='p-4 bg-purple-800 rounded-full'>
                            <FcShop className='w-7 h-7' />
                        </div>
                        <div className='text-sm font-bold'>{business ? <span>{business.name}</span> : <Skeleton w={'100px'} h={'20px'} />}</div>
                    </div>
                </div> */}

        <Form className="mb-8" form={form} onFinish={createSavings}>
          <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold dark:text-white">
            Savings Information
          </div>
          <div className="grid grid-cols-1 gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
            {Object.keys(createSavingsInput)
              .filter(
                (f) =>
                  f !== "instalment" &&
                  f !== "request_info" &&
                  f !== "description"
              )
              .map((fieldName, i) => (
                <div key={i} className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex justify-between capitalize">
                    {upperCaseFirst(removeUnderscore(fieldName))}
                    {checkForInfo(fieldName)}
                  </div>
                  {Object.keys(isSelectTag).includes(fieldName) ? (
                    <Form.Item
                      name={fieldName}
                      rules={[
                        {
                          required: true,
                          message: `${upperCaseFirst(
                            removeUnderscore(fieldName)
                          )} is required`,
                        },
                      ]}
                    >
                      <select
                        value={(createSavingsInput as any)[fieldName]}
                        className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                        onChange={(event) => {
                          handleInputChange(event, fieldName);
                          form.setFieldValue(fieldName, event.target.value);
                        }}
                        disabled={isSending}
                      >
                        <option value="">Select</option>
                        {((isSelectTag as any)[fieldName] as string[]).map(
                          (e, k) => (
                            <option value={e} key={k}>
                              {getActualSelectValue(e)}
                            </option>
                          )
                        )}
                      </select>
                    </Form.Item>
                  ) : (
                    // : IsTextArea.includes(fieldName) ? (
                    //   <textarea
                    //     value={(createSavingsInput as any)[fieldName]}
                    //     className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    //     onChange={(event) => handleInputChange(event, fieldName)}
                    //     placeholder={upperCaseFirst(removeUnderscore(fieldName))}
                    //     rows={3}
                    //     disabled={isSending}
                    //   ></textarea>
                    // )
                    <Form.Item
                      name={fieldName}
                      rules={[
                        {
                          required: true,
                          message: `${upperCaseFirst(
                            removeUnderscore(fieldName)
                          )} is required`,
                        },
                      ]}
                    >
                      <input
                        type="text"
                        value={(createSavingsInput as any)[fieldName]}
                        className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                        onChange={(event) => {
                          handleInputChange(event, fieldName);
                          form.setFieldValue(fieldName, event.target.value);
                        }}
                        placeholder={upperCaseFirst(
                          removeUnderscore(fieldName)
                        )}
                        disabled={isSending}
                      />
                    </Form.Item>
                  )}
                </div>
              ))}

            <div className="col-span-1 mb-8 md:col-span-2 lg:col-span-4">
              <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex">
                Description
                {checkForInfo("description")}
              </div>
              <Form.Item
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: `${upperCaseFirst(
                      removeUnderscore("description")
                    )} is required`,
                  },
                ]}
              >
                <textarea
                  value={createSavingsInput.description}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  onChange={(event) => {
                    {
                      handleInputChange(event, "description");
                      form.setFieldValue("description", event.target.value);
                    }
                  }}
                  placeholder="Description"
                  rows={3}
                  disabled={isSending}
                ></textarea>
              </Form.Item>
            </div>
          </div>

          <div className="mb-10">
            <div className="flex items-center justify-between mb-5 text-sm">
              <div className="border-b-2 border-purple-800 pb-[3px] font-bold dark:text-white">
                Request information
              </div>
              <div
                className="text-xs font-bold text-purple-700 cursor-pointer dark:text-purple-500"
                onClick={() => addMoreRequestInfo()}
              >
                Add section
              </div>
            </div>
            {createSavingsInput.request_info.length > 0 ? (
              createSavingsInput.request_info.map((request_info, index) => (
                <div
                  key={index}
                  className="p-3 mb-3 rounded-lg bg-slate-100 dark:bg-neutral-800"
                >
                  <div className="grid grid-cols-1 gap-5 mb-3 md:grid-cols-2 lg:grid-cols-4">
                    {Object.keys(request_info).map((fieldName, i) => (
                      <div key={i} className="">
                        <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
                          {upperCaseFirst(removeUnderscore(fieldName))}
                        </div>
                        {Object.keys(isSelectTagRequireInfo).includes(
                          fieldName
                        ) ? (
                          <select
                            value={
                              fieldName === "options"
                                ? formatRequiredInfoOptionsView(
                                    (
                                      createSavingsInput.request_info[
                                        index
                                      ] as any
                                    )[fieldName]
                                  )
                                : (
                                    createSavingsInput.request_info[
                                      index
                                    ] as any
                                  )[fieldName]
                            }
                            className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                            onChange={(event) =>
                              handleInputChange_require_info(
                                event,
                                fieldName,
                                index
                              )
                            }
                            disabled={isSending}
                          >
                            <option value="">Select</option>
                            {(
                              (isSelectTagRequireInfo as any)[
                                fieldName
                              ] as string[]
                            ).map((e, k) => (
                              <option value={e} key={k}>
                                {e}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            value={
                              fieldName === "options"
                                ? formatRequiredInfoOptionsView(
                                    (
                                      createSavingsInput.request_info[
                                        index
                                      ] as any
                                    )[fieldName]
                                  )
                                : (
                                    createSavingsInput.request_info[
                                      index
                                    ] as any
                                  )[fieldName]
                            }
                            className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                            onChange={(event) =>
                              handleInputChange_require_info(
                                event,
                                fieldName,
                                index
                              )
                            }
                            placeholder={
                              fieldName === "options"
                                ? "option1, option2, option3"
                                : upperCaseFirst(removeUnderscore(fieldName))
                            }
                            disabled={isSending}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="mb-2">
                    <div
                      className="text-xs text-red-500 cursor-pointer"
                      onClick={() => deleteRequestInfo(index)}
                    >
                      delete section
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-3 mb-3 text-sm rounded-lg bg-slate-100 dark:bg-neutral-800">
                Click on "Add section" to add form group
              </div>
            )}
          </div>

          <div>
            <button
              className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
              // onClick={() => createSavings()}
              disabled={isSending}
              type="submit"
            >
              {isSending ? (
                <ImSpinner8 className="w-5 h-5 animate-spin" />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default BusinessServicesSavingsCreateClient;
