"use client";

import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import http from "@/lib/http";
import { useToast } from "@chakra-ui/react";
// import classNames from 'classnames';
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";
import { ICreateInvestment } from "@/types/global.types";
import { removeUnderscore, upperCaseFirst } from "@/utils/helpers";
import { useNavigate } from "react-router-dom";
import { Form, Tooltip } from "antd";
import { HiInformationCircle } from "react-icons/hi";

function BusinessServicesInvestmentCreateClient() {
  // const { user } = useApp();
  const navigate = useNavigate();
  const toast = useToast();
  const [form] = Form.useForm();

  const [isSending, setIsSending] = useState(false);

  const notRequired = [
    "video",
    "image_col_1",
    "image_col_2",
    "image_col_3",
    "image_col_4",
  ];

  const infos = [
    {
      key: "currency",
      value: "The currency in which the investment amount is denominated",
    },
    {
      key: "type",
      value: "The category or type of investment",
    },
    {
      key: "quantity",
      value: "The number of units or shares to be invested",
    },
    {
      key: "name",
      value: "The specific name or ticker symbol of the investment",
    },
    {
      key: "description",
      value:
        "A brief overview of the investment, including its objectives, risks, and potential returns",
    },
    {
      key: "collection",
      value: "The method or frequency of collecting investment funds",
    },
    {
      key: "rate",
      value: "The expected rate of return on the investment",
    },
    {
      key: "rate_amount_plus",
      value: "Any additional fees or charges associated with the investment",
    },
    {
      key: "rate_amount_plus_limit",
      value: "The maximum amount of additional fees",
    },
    {
      key: "min_amount",
      value: "The minimum and maximum investment amounts",
    },
    {
      key: "max_amount",
      value: "The maximum and maximum investment amounts",
    },
    {
      key: "min_duration",
      value: `The shortest  investment timeframes.`,
    },
    {
      key: "max_duration",
      value: `The longest investment timeframes.`,
    },
    {
      key: "active",
      value: "Indicates whether the investment is currently available",
    },
    {
      key: "auto_approve",
      value: "Whether the investment is automatically approved",
    },
    {
      key: "auto_decline",
      value: "Whether the investment is automatically declined",
    },
    {
      key: "send_request_status_sms",
      value:
        "Indicates whether to send sms notifications about the investment request status",
    },
    {
      key: "send_request_status_email",
      value:
        "Indicates whether to send email notifications about the investment request status",
    },
    {
      key: "interest_settlement",
      value: "The method or frequency of paying out interest or dividends",
    },
  ];

  const checkForInfo = (key: string) => {
    let checking = infos.filter((item) => item.key === key);
    if (checking.length > 0) {
      return (
        <div>
          <Tooltip title={checking[0].value}>
            <HiInformationCircle className="w-5 h-5 pl-1 !text-yellow-500 cursor-pointer" />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const [createInvestmentInput, setCreateInvestmentInput] =
    useState<ICreateInvestment>({
      currency: "",
      type: "",
      quantity: "",
      name: "",
      description: "",
      collection: "",
      rate: "",
      rate_amount_plus: "",
      rate_amount_plus_limit: "",
      min_amount: "",
      max_amount: "",
      min_duration: "",
      max_duration: "",
      active: "",
      auto_approve: "",
      auto_decline: "",
      send_request_status_sms: "",
      send_request_status_email: "",
      interest_settlement: "",
      video: "",
      image_col_1: "",
      image_col_2: "",
      image_col_3: "",
      image_col_4: "",
      request_info: [],
    });

  const isSelectTag = {
    currency: ["NGN", "USD"],
    type: ["standard"],
    active: ["Yes", "No"],
    auto_approve: ["Yes", "No"],
    auto_decline: ["Yes", "No"],
    send_request_status_sms: ["Yes", "No"],
    send_request_status_email: ["Yes", "No"],
    interest_settlement: ["Upfront", "Maturity"],
  };

  const isSelectTagRequireInfo = {};

  const IsTextArea = ["description"];

  async function createInvestment() {
    setIsSending(true);
    console.log(createInvestmentInput);
    try {
      const resp = await http("services/addon/investment/create", {
        ...createInvestmentInput,
        currency: createInvestmentInput?.currency?.toLowerCase(),
      });
      console.log("create resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/d/services/investment");
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    fieldName: string
  ) => {
    setCreateInvestmentInput((prevInput) => ({
      ...prevInput,
      [fieldName]: event.target.value,
    }));
  };

  const handleInputChange_require_info = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string,
    index: number
  ) => {
    if (fieldName !== "options") {
      setCreateInvestmentInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index ? { ...info, [fieldName]: event.target.value } : info
        ),
      }));
    } else {
      setCreateInvestmentInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index
            ? {
                ...info,
                [fieldName as any]: formatRequiredInfoOptionsStore(
                  event.target.value
                ),
              }
            : info
        ),
      }));
    }
  };

  function deleteRequestInfo(index: number) {
    setCreateInvestmentInput((prevInput) => ({
      ...prevInput,
      request_info: prevInput.request_info.filter((_, i) => i !== index),
    }));
  }

  function addMoreRequestInfo() {
    setCreateInvestmentInput((prevInput) => ({
      ...prevInput,
      request_info: [
        ...prevInput.request_info,
        {
          name: "",
          required: "",
          options: [],
          value: "",
          type: "",
        },
      ],
    }));
  }

  function formatRequiredInfoOptionsView(data: string[]): string {
    if (data.length > 0) {
      let r = "";
      data.forEach((e, i) => {
        if (i !== data.length - 1) {
          r += e + ",";
        } else {
          r += e;
        }
      });
      return r;
    }
    return "";
  }

  function formatRequiredInfoOptionsStore(data: string): string[] {
    const arr = data.split(",");
    let s: string[] = [];
    if (arr.length > 0) {
      arr.forEach((e) => {
        s.push(e);
      });
      return s;
    }
    return [];
  }

  const getActualSelectValue = (value: string) => {
    switch (value) {
      case "standard":
        return "Standard";

      case "portfolio":
        return "Portfolio";

      default:
        return value;
    }
  };

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/details");
            }}
          >
            Services
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/investment");
            }}
          >
            Investment
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/investment/create");
            }}
          >
            Create
          </div>
        </div>
      </div>

      <div className="mb-8 text-xl">Create Investment</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      <div className="w-full mb-10">
        {/* <div className='flex items-center justify-between mb-10'>
                    <div className='flex items-center gap-3'>
                        <div className='p-4 bg-purple-800 rounded-full'>
                            <FcShop className='w-7 h-7' />
                        </div>
                        <div className='text-sm font-bold'>{business ? <span>{business.name}</span> : <Skeleton w={'100px'} h={'20px'} />}</div>
                    </div>
                </div> */}

        <Form className="mb-8" form={form} onFinish={createInvestment}>
          <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold dark:text-white">
            Investment Information
          </div>
          <div className="grid grid-cols-1 gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
            {Object.keys(createInvestmentInput)
              .filter(
                (f) =>
                  f !== "instalment" &&
                  f !== "request_info" &&
                  f !== "description"
              )
              .map((fieldName, i) => (
                <div key={i} className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex justify-between capitalize">
                    {upperCaseFirst(removeUnderscore(fieldName))}
                    {checkForInfo(fieldName)}
                  </div>
                  {Object.keys(isSelectTag).includes(fieldName) ? (
                    <Form.Item
                      name={fieldName}
                      rules={[
                        {
                          required: true,
                          message: `${upperCaseFirst(
                            removeUnderscore(fieldName)
                          )} is required`,
                        },
                      ]}
                    >
                      <select
                        value={(createInvestmentInput as any)[fieldName]}
                        className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                        onChange={(event) => {
                          handleInputChange(event, fieldName);
                          form.setFieldValue(fieldName, event.target.value);
                        }}
                        disabled={isSending}
                      >
                        <option value="">Select</option>
                        {((isSelectTag as any)[fieldName] as string[]).map(
                          (e, k) => (
                            <option value={e} key={k}>
                              {getActualSelectValue(e)}
                            </option>
                          )
                        )}
                      </select>
                    </Form.Item>
                  ) : (
                    // : IsTextArea.includes(fieldName) ? (
                    //   <textarea
                    //     value={(createInvestmentInput as any)[fieldName]}
                    //     className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    //     onChange={(event) => handleInputChange(event, fieldName)}
                    //     placeholder={upperCaseFirst(removeUnderscore(fieldName))}
                    //     rows={3}
                    //     disabled={isSending}
                    //   ></textarea>
                    // )
                    <Form.Item
                      name={fieldName}
                      rules={[
                        {
                          required: notRequired.includes(fieldName)
                            ? false
                            : true,
                          message: `${upperCaseFirst(
                            removeUnderscore(fieldName)
                          )} is required`,
                        },
                      ]}
                    >
                      <input
                        type="text"
                        value={(createInvestmentInput as any)[fieldName]}
                        className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                        onChange={(event) => {
                          handleInputChange(event, fieldName);
                          form.setFieldValue(fieldName, event.target.value);
                        }}
                        placeholder={upperCaseFirst(
                          removeUnderscore(fieldName)
                        )}
                        disabled={isSending}
                      />
                    </Form.Item>
                  )}
                </div>
              ))}

            <div className="col-span-1 mb-8 md:col-span-2 lg:col-span-4">
              <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex">
                Description
                {checkForInfo("description")}
              </div>
              <Form.Item
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: `${upperCaseFirst(
                      removeUnderscore("description")
                    )} is required`,
                  },
                ]}
              >
                <textarea
                  value={createInvestmentInput.description}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  onChange={(event) => {
                    handleInputChange(event, "description");
                    form.setFieldValue("description", event.target.value);
                  }}
                  placeholder="Description"
                  rows={3}
                  disabled={isSending}
                ></textarea>
              </Form.Item>
            </div>
          </div>

          <div className="mb-10">
            <div className="flex items-center justify-between mb-5 text-sm">
              <div className="border-b-2 border-purple-800 pb-[3px] font-bold dark:text-white">
                Request information
              </div>
              <div
                className="text-xs font-bold text-purple-700 cursor-pointer dark:text-purple-500"
                onClick={() => addMoreRequestInfo()}
              >
                Add section
              </div>
            </div>
            {createInvestmentInput.request_info.length > 0 ? (
              createInvestmentInput.request_info.map((request_info, index) => (
                <div
                  key={index}
                  className="p-3 mb-3 rounded-lg bg-slate-100 dark:bg-neutral-800"
                >
                  <div className="grid grid-cols-1 gap-5 mb-3 md:grid-cols-2 lg:grid-cols-4">
                    {Object.keys(request_info).map((fieldName, i) => (
                      <div key={i} className="">
                        <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
                          {upperCaseFirst(removeUnderscore(fieldName))}
                        </div>
                        {Object.keys(isSelectTagRequireInfo).includes(
                          fieldName
                        ) ? (
                          <select
                            value={
                              fieldName === "options"
                                ? formatRequiredInfoOptionsView(
                                    (
                                      createInvestmentInput.request_info[
                                        index
                                      ] as any
                                    )[fieldName]
                                  )
                                : (
                                    createInvestmentInput.request_info[
                                      index
                                    ] as any
                                  )[fieldName]
                            }
                            className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                            onChange={(event) =>
                              handleInputChange_require_info(
                                event,
                                fieldName,
                                index
                              )
                            }
                            disabled={isSending}
                          >
                            <option value="">Select</option>
                            {(
                              (isSelectTagRequireInfo as any)[
                                fieldName
                              ] as string[]
                            ).map((e, k) => (
                              <option value={e} key={k}>
                                {e}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            value={
                              fieldName === "options"
                                ? formatRequiredInfoOptionsView(
                                    (
                                      createInvestmentInput.request_info[
                                        index
                                      ] as any
                                    )[fieldName]
                                  )
                                : (
                                    createInvestmentInput.request_info[
                                      index
                                    ] as any
                                  )[fieldName]
                            }
                            className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                            onChange={(event) =>
                              handleInputChange_require_info(
                                event,
                                fieldName,
                                index
                              )
                            }
                            placeholder={
                              fieldName === "options"
                                ? "option1, option2, option3"
                                : upperCaseFirst(removeUnderscore(fieldName))
                            }
                            disabled={isSending}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="mb-2">
                    <div
                      className="text-xs text-red-500 cursor-pointer"
                      onClick={() => deleteRequestInfo(index)}
                    >
                      delete section
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-3 mb-3 text-sm rounded-lg bg-slate-100 dark:bg-neutral-800">
                Click on "Add section" to add form group
              </div>
            )}
          </div>

          <div>
            <button
              className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
              // onClick={() => createInvestment()}
              disabled={isSending}
              type="submit"
            >
              {isSending ? (
                <ImSpinner8 className="w-5 h-5 animate-spin" />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default BusinessServicesInvestmentCreateClient;
